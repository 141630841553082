// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-creating-js": () => import("./../../../src/pages/creating.js" /* webpackChunkName: "component---src-pages-creating-js" */),
  "component---src-pages-culture-media-js": () => import("./../../../src/pages/culture-media.js" /* webpackChunkName: "component---src-pages-culture-media-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-library-archive-js": () => import("./../../../src/pages/library-archive.js" /* webpackChunkName: "component---src-pages-library-archive-js" */),
  "component---src-pages-media-archive-js": () => import("./../../../src/pages/media-archive.js" /* webpackChunkName: "component---src-pages-media-archive-js" */)
}

